.transaction-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .calendar-page {
    margin-right: 10px;
  }

  .total {
    flex: 1;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
  }
}
