$side-menu-z-index: 10000;
$content-backdrop-z-index: $side-menu-z-index - 1;
$content-backdrop-bg: rgba(black, 0.4);
$side-menu-bg: #fff;
$side-menu-link-color: #000;
$side-menu-link-bg-active: #eee;
$side-menu-divider-bg: #e5e5e5;

.side-menu {
  position: fixed;
  background: $side-menu-bg;
  top: 0;
  bottom: 0;
  right: 0;
  left: -70%;
  width: 70%;
  overflow: auto;
  z-index: $side-menu-z-index;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.3);
  will-change: transform;

  ul {
    li {
      line-height: 32px;

      &.divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: $side-menu-divider-bg;
      }

      &.header {
        padding-left: 15px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

@mixin toggle-bar {
  content: "";
  display: block;
  height: 5px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
  transition: all 0.3s ease-in-out;
}

.side-menu-toggle {
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span.toggle-bars {
    @include toggle-bar;

    &:before {
      @include toggle-bar;
      top: -15px;
    }

    &:after {
      @include toggle-bar;
      top: 10px;
    }
  }
}

.disable-scroll {
  overflow: hidden;
}

.content-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $content-backdrop-z-index;
  background-color: $content-backdrop-bg;
  opacity: 0;
  visibility: hidden;
  will-change: opacity;
}
