.calendar-page {
  text-transform: uppercase;

  .month {
    font-size: 10px;
  }

  .day {
    font-size: 18px;
    line-height: 18px;
  }
}
