.previous-events-container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.previous-events {
  text-align: center;

  .btn-group {
    margin: 10px 15px 10px 0px;
  }
}
